import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

const ProtectedRoute: ({
  children,
}: {
  children: React.ReactNode;
}) => React.JSX.Element = ({ children }: { children: ReactNode }) => {
  const auth: any = useAuth();
  const location = useLocation();
  if (!auth?.accessToken) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return <>{children}</>;
};

export default ProtectedRoute;
