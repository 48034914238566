import Template from "../components/Template";
import * as DashboardActions from "../redux/actions/DashboardActions";
import React, { useEffect, useState } from "react";
import { useActions } from "../redux/actions/UseActions";
import { Button, Grid, Progress, Segment } from "semantic-ui-react";
import { STATUS_COLOR_MAP } from "../models/PatientStatus";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";

interface IDashboardEntry {
  name: string;
  value: number;
}

interface IDashboard {
  [name: string]: IDashboardEntry[];
}

interface ISummary {
  [name: string]: { total: number };
}

const Dashboard = () => {
  const actions = useActions(DashboardActions);
  const navigate = useNavigate();
  const [data, setData] = useState<IDashboard>();
  const [summary, setSummary] = useState<ISummary>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData().then();
  }, []);

  const loadData = async () => {
    setLoading(true);
    const data: IDashboard = await actions.getSummary("week");
    let summary = {} as ISummary;
    Object.entries(data).forEach(([key, data]) => {
      const total = data.reduce((acc, cur) => acc + (cur.value ?? 0), 0);
      summary[key] = { total };
    });
    setData(data);
    setSummary(summary);
    setLoading(false);
  };

  const getDashboardSegment = (
    key: string,
    entries: IDashboardEntry[],
    summaryCardIndex?: number,
  ) => {
    const isSummary = summaryCardIndex !== undefined;
    return (
      <Segment inverted={isSummary}>
        <h4>
          {summaryCardIndex !== undefined && (
            <Button
              onClick={() =>
                navigate(summaryCardIndex ? "/patients" : "/api-configurations")
              }
              content="Go To"
              size="tiny"
              basic
              primary
              className="float-right"
            />
          )}
          {key.toUpperCase()} ({(summary && summary[key]?.total) || 1})
        </h4>
        {entries.map((entry) => (
          <Progress
            content={entry.name}
            key={entry.name + entry.value}
            value={entry.value}
            total={(summary && summary[key]?.total) || 1}
            color={STATUS_COLOR_MAP[entry.name]}
            progress="value"
            size="medium"
            inverted={isSummary}
            active
          />
        ))}
      </Segment>
    );
  };

  return (
    <Template activeLink="dashboard">
      <h1 className="text-center">Weekly Dashboard</h1>
      {loading && <Loader></Loader>}
      <Grid columns={2} divided>
        <Grid.Column width={4}>
          {Object.entries(data ?? {})
            .slice(0, 2)
            .map(([key, entries], i) => (
              <Grid.Column key={key}>
                {getDashboardSegment(key, entries, i)}
                <br />
              </Grid.Column>
            ))}
        </Grid.Column>
        <Grid.Column width={12}>
          <Grid columns={2}>
            {Object.entries(data ?? {})
              .slice(2, 10)
              .map(([key, entries], i) => (
                <Grid.Column key={key}>
                  {getDashboardSegment(key, entries)}
                </Grid.Column>
              ))}
          </Grid>
        </Grid.Column>
      </Grid>
    </Template>
  );
};

export default Dashboard;
