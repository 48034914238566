import React from "react";
import { Sidebar, Menu, Icon } from "semantic-ui-react";
import { MainSideBarProps } from "../models/MainSideBarProp";
import { MENUS } from "../utils/MENUS";
import { Link, useLocation } from "react-router-dom";

const MainSideBar: React.FC<MainSideBarProps> = (props: MainSideBarProps) => {
  const location = useLocation();
  let basePath = location.pathname.split("/")[1];
  basePath =
    basePath.indexOf("-") > -1 ? basePath.split("-").join(" ") : basePath;
  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      vertical
      onHide={() => props.toggleSideTab(false)}
      visible={props.visible}
    >
      <div
        className="clickable"
        onClick={() => props.toggleSideTab()}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            props.toggleSideTab();
          }
        }}
      >
        <img
          src="/images/logoMain.png"
          className="logo"
          alt="Logo"
          onClick={props.logoRedirect}
        ></img>
      </div>
      <div>
        {MENUS.map((menu) => {
          return (
            <Menu.Item
              key={`${menu.name}_menu`}
              active={basePath.toLowerCase() === menu.name.toLowerCase()}
            >
              <Link to={`/${menu.name}`} onClick={() => props.toggleSideTab}>
                <Icon name={menu.icon} />
                &nbsp; &nbsp; &nbsp;
                {menu.title}
              </Link>
            </Menu.Item>
          );
        })}
      </div>
    </Sidebar>
  );
};
export default MainSideBar;
