import CreateReducer from "./CreateReducer";
import { AuthDetails } from "../../models/AuthDetails";
import { ActionType } from "../../models/ActionType";
import { Action } from "../../models/Action";
import { removeAuth, setAuth } from "../../providers/AuthProvider";

export const authReducer = CreateReducer(
  {},
  {
    [ActionType.LOGIN](state: AuthDetails, action: Action<AuthDetails>) {
      setAuth(action.payload);
      return action.payload;
    },
    [ActionType.LOGIN_FAILED](state: AuthDetails, action: any) {
      removeAuth();
      return action.payload;
    },
  },
);
