import React, { useCallback, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { useActions } from "../redux/actions/UseActions";
import * as AuthActions from "../redux/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import {
  getCurrentPage,
  removeCurrentPage,
  useAuth,
} from "../providers/AuthProvider";
import { useGoogleLogin } from "@react-oauth/google";
import { ProfileRequest } from "../models/ProfileRequest";
import { defaultHomePage } from "../utils/constants";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const SignIn = () => {
  const navigate = useNavigate();
  const swal = withReactContent(Swal);
  const authActions = useActions(AuthActions);
  const auth: any = useAuth();
  const redirectToMainPage = useCallback(() => {
    const redirectPath = getCurrentPage();
    if (redirectPath) {
      removeCurrentPage();
      navigate(`${redirectPath}`);
      return;
    }
    navigate(`/${defaultHomePage}`);
  }, [navigate]);

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    if (token) {
      cdpLogin(token).then();
    } else {
      if (auth?.accessToken) {
        redirectToMainPage();
      }
    }
  }, [auth?.accessToken, redirectToMainPage]);

  const cdpLogin = async (token: string) => {
    let details = await authActions.cdpLogin(token);
    if (!details) {
      await alertError();
      return;
    }
    redirectToMainPage();
  };

  const googleLogin = async (data: any) => {
    const profile = await authActions.getGoogleUserDetails(data.access_token);
    if (!profile) {
      await alertError();
      return;
    }
    let profileObj: ProfileRequest = {
      email: profile?.email,
      profilePicture: profile?.picture,
      firstName: profile?.givenName,
    };
    let details = await authActions.LoginWithGoogle(profileObj);
    if (!details) {
      await alertError();
      return;
    }
    redirectToMainPage();
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      googleLogin(codeResponse).then();
    },
    onError: () => {
      alertError().then();
    },
  });

  const alertError = async () => {
    await swal.fire({
      title: "Error",
      text: "Failed to login",
      icon: "error",
    });
  };

  return (
    <div className="login">
      <div className="container-fluid">
        <Grid>
          <Grid.Row>
            <Grid.Column className="sectionLeft" computer={8} tablet={6}>
              {" "}
            </Grid.Column>
            <Grid.Column computer={8} tablet={10} mobile={16}>
              <img
                src="/images/logoMain.png"
                className="loginLogo"
                alt="Logo"
              ></img>
              <Grid centered>
                <Grid.Row centered>
                  <Grid.Column computer={14} tablet={16} mobile={16}>
                    <Grid>
                      <Grid.Row centered>
                        <button
                          onClick={() => login()}
                          type="button"
                          className="login-with-google-btns"
                        >
                          Sign in with Google
                        </button>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default SignIn;
