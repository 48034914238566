import { convertToQueryParams } from "../../utils/converter";

export const getPatients =
  (data: any) => async (dispatch: Function, _getState: Function, api: any) => {
    try {
      const queryParams = convertToQueryParams(data).replace(/where\./g, "");
      return await api.get(`patient?${queryParams}`);
    } catch (err) {
      console.error(err);
      return null;
    }
  };

export const updatePatientStatus =
  (id: number, status: string) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      return await api.put(`patient/${id}/status/${status}`);
    } catch (err) {
      console.error(err);
      return null;
    }
  };
