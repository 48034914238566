export const API_URL: string =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5010"
    : "https://apiopendental.cdp.dental";

export const defaultHomePage = "dashboard";

export const CDP_MY_URL: string =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://my.cdp.dental";

export const CARRIER_LIST = ["DENTA", "MCNA", "UHC"];
