export enum PatientStatus {
  ALREADY_EXISTS = "ALREADY_EXISTS",
  MISMATCH_PAT_NUM = "MISMATCH_PAT_NUM",
  FOUND_MATCHES = "FOUND_MATCHES",
  CREATED = "CREATED",
  CREATED_PATFIELDS_FAILED = "CREATED_PATFIELDS_FAILED",
  PAT_NUM_NOT_FOUND = "PAT_NUM_NOT_FOUND",
  FAILED = "FAILED",
  INVALID = "INVALID",
  RETRY = "RETRY",
  PROSPECTIVE = "PROSPECTIVE",
}

export const STATUS_COLOR_MAP: { [key: string]: any } = {
  [PatientStatus.ALREADY_EXISTS]: "brown",
  [PatientStatus.MISMATCH_PAT_NUM]: "purple",
  [PatientStatus.PAT_NUM_NOT_FOUND]: "orange",
  [PatientStatus.FOUND_MATCHES]: "brown",
  [PatientStatus.CREATED]: "green",
  [PatientStatus.CREATED_PATFIELDS_FAILED]: "orange",
  [PatientStatus.FAILED]: "red",
  [PatientStatus.INVALID]: "red",
  [PatientStatus.RETRY]: "red",
  [PatientStatus.PROSPECTIVE]: "blue",
  COMPLETED_APT: "violet",
  SCHEDULED: "purple",
  Error: "red",
  Success: "green",
};

export const STATUS_MESSAGE_MAP: { [key: string]: string } = {
  [PatientStatus.ALREADY_EXISTS]: "Patient with PATNUM already exists",
  [PatientStatus.MISMATCH_PAT_NUM]:
    "Found patient with same PATNUM but different name",
  [PatientStatus.FOUND_MATCHES]: "Found patient with same name",
  [PatientStatus.CREATED]: "Patient successfully posted to Open Dental",
  [PatientStatus.PAT_NUM_NOT_FOUND]:
    "Patient has patient number, but it does not exist in Open Dental",
  [PatientStatus.CREATED_PATFIELDS_FAILED]:
    "Patient successfully posted to Open Dental, but could not save PATFIELDS",
};
