import { ExtractedData, Patient } from "../models/Patient";
import moment from "moment/moment";

export function convertToQueryParams(obj: any, prefix = ""): string {
  return Object.entries(obj ?? {})
    .map(([key, value]) => {
      const paramName = prefix ? `${prefix}.${key}` : key;
      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        return convertToQueryParams(value, paramName);
      }
      return `${paramName}=${value}`;
    })
    .join("&");
}

export function mapPatientInformation(patient: Patient) {
  const extractedData = parseData(patient.extractedData);
  return {
    First_Name: patient.firstName,
    Last_Name: patient.lastName,
    Phone: extractedData?.Phone,
    Address1: extractedData?.Address1,
    Date_of_Birth: patient.dateOfBirth,
    Subscriber: patient.subscriberId,
    City: extractedData?.City,
    MCO: patient.mco,
    Practice: patient.practice?.practice,
    Status: patient.status,
    PatNum: patient.patNum,
    DatePosted: moment(patient.createdAt).format("DD-MM-YYYY"),
  };
}

export const parsePatientData = (patient: Patient): Patient => {
  patient.parsedData = parseData(patient.extractedData);
  return patient;
};

export const parseData = (
  extractedData: string | undefined,
): ExtractedData | undefined => {
  try {
    return extractedData ? JSON.parse(extractedData) : undefined;
  } catch (e) {
    return undefined;
  }
};
