import React from "react";
import { Menu, Dropdown, Icon } from "semantic-ui-react";
import { MainHeaderProps } from "../models/MainHeaderProp";
import { useNavigate } from "react-router-dom";
import { removeAuth } from "../providers/AuthProvider";

const MainHeader: React.FC<MainHeaderProps> = (props: MainHeaderProps) => {
  const navigate = useNavigate();
  const logout = () => {
    removeAuth();
    navigate("/");
  };

  return (
    <Menu fixed="top" className="main-menu">
      <Menu.Item
        header
        className="ml-0 mr-0 mr-lg-4 float-left"
        icon="content"
        onClick={props.toggleSideTab}
      />
      <div className="clickable mt-15">
        <img
          src="/images/logoSmall.png"
          className="logoSmall"
          alt="Logo"
          onClick={props.logoRedirect}
        />
      </div>
      <Menu.Menu position="right" className="float-right float-lg-none">
        <Dropdown
          className="setting inverted item mr-0"
          icon="ellipsis vertical"
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={logout}>
              <Icon name="log out" /> Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
};

export default MainHeader;
