import Template from "../components/Template";
import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Form,
  Grid,
  Icon,
  Label,
  Pagination,
  PaginationProps,
  Table,
} from "semantic-ui-react";
import { useActions } from "../redux/actions/UseActions";
import * as PatientLogActions from "../redux/actions/PatientLogActions";
import { PatientLog, PatientSyncLog } from "../models/PatientLog";
import { PageRequest, PageResponse } from "../models/Pagination";
import moment from "moment";
import PatientLogModal from "../components/PatientLogModal";
import ReactDatePicker from "react-datepicker";
import * as PracticeActions from "../redux/actions/PracticeActions";
import { Practice } from "../models/Practice";
import { PatientStatus, STATUS_COLOR_MAP } from "../models/PatientStatus";
import { HELP_PAGE } from "../utils/HELP_PAGE";
import { HelpMark } from "../components/help/HelpMark";

const PatientLogs = () => {
  const patientLogActions = useActions(PatientLogActions);
  const practiceActions = useActions(PracticeActions);
  const [syncLog, setSyncLog] = useState<PatientLog | undefined>();
  const [practices, setPractices] = useState<PageResponse<Practice>>({
    data: [],
    count: 0,
  });
  const [pagedData, setPagedData] = useState<PageResponse<PatientLog>>({
    data: [],
    count: 0,
  });
  const [pageRequest, setPageRequest] = useState<PageRequest>({
    limit: 14,
    offset: 0,
    orderBy: "createdAt",
    direction: "DESC",
    where: {
      createdAt: `${moment(new Date())
        .subtract(1, "month")
        .format("YYYY-MM-DD")};${moment(new Date()).format("YYYY-MM-DD")}`,
    },
  });
  const [loading, setLoading] = useState(true);
  const { limit, offset } = pageRequest;

  useEffect(() => {
    loadPractices().then();
  }, []);

  useEffect(() => {
    loadPatientLogs().then();
  }, [pageRequest]);

  const setFilter = (name: string, value: any) => {
    setPageRequest({
      ...pageRequest,
      where: { ...pageRequest.where, [name]: value },
      offset: 0,
    });
  };

  const loadPractices = async () => {
    const data = await practiceActions.getPractices();
    data && setPractices(data);
  };

  const loadPatientLogs = async () => {
    setLoading(true);
    const data = await patientLogActions.getLogs(pageRequest);
    data && setPagedData({ count: data.count, data: data.rows });
    setLoading(false);
  };

  const onPageChange = (data: PaginationProps) => {
    setPageRequest({
      ...pageRequest,
      offset: (+(data.activePage ?? 1) - 1) * limit,
      limit,
    });
  };

  const logsTags = (data: PatientSyncLog[] = []) => {
    const obj = data.reduce(
      (acc, cur) => {
        acc[cur?.patient?.status ?? ""] = (acc[cur?.patient?.status] ?? 0) + 1;
        return acc;
      },
      {} as { [key: string]: number },
    );

    return Object.entries(obj)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([key, value]) => (
        <Label
          color={STATUS_COLOR_MAP[key]}
          basic={true}
          content={value}
          detail={key}
          size="small"
          key={`${key}_label`}
        />
      ));
  };

  const handleOnSort = (name: string) => {
    let direction: "ASC" | "DESC";
    if (pageRequest.direction == "ASC") {
      direction = pageRequest.orderBy != name ? "ASC" : "DESC";
    } else {
      direction = "ASC";
    }
    setPageRequest({ ...pageRequest, orderBy: name, direction });
  };

  const getSorting = (name: string, label: string) => {
    const iconName =
      pageRequest.direction == "DESC"
        ? "sort content descending"
        : "sort content ascending";
    return (
      <span
        aria-label={`Sort by ${label}`}
        className="cursor-pointer"
        onClick={() => handleOnSort(name)}
      >
        {label} {pageRequest.orderBy == name && <Icon name={iconName} />}
      </span>
    );
  };

  return (
    <Template activeLink="dashboard" loading={loading}>
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <h1>
                Patient Logs ({pagedData.count}){" "}
                <HelpMark helpPage={HELP_PAGE.Patient_Logs} />
              </h1>
            </Grid.Column>
            <Grid.Column width={11}>
              <Form>
                <Form.Group inline={true} className="float-right">
                  <Form.Field>
                    <Dropdown
                      placeholder="Patient Status"
                      search
                      clearable
                      selection
                      options={Object.keys(PatientStatus).map((key) => ({
                        text: key,
                        value: key,
                        key,
                      }))}
                      onChange={(e, data: any) =>
                        setFilter("patientStatus", data.value)
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Dropdown
                      search
                      selection
                      clearable
                      options={(practices?.data ?? []).map(
                        ({ id, practice }) => ({ text: practice, value: id }),
                      )}
                      onChange={(_, { value }) =>
                        setFilter("practiceId", value)
                      }
                      placeholder="Select Practices"
                    />
                  </Form.Field>
                  <Form.Field>
                    <ReactDatePicker
                      selected={
                        pageRequest.where?.createdAt.split(";")[0]
                          ? moment(
                              pageRequest.where?.createdAt.split(";")[0],
                              "YYYY-MM-DD",
                            ).toDate()
                          : undefined
                      }
                      onChange={(d) =>
                        setFilter(
                          "createdAt",
                          (d
                            ? moment(d).format("YYYY-MM-DD")
                            : moment(new Date("2010-01-01")).format(
                                "YYYY-MM-DD",
                              )) +
                            `;${pageRequest.where?.createdAt.split(";")[1]}`,
                        )
                      }
                      dateFormat="dd MMM yyyy"
                      placeholderText="Select Date From"
                    />
                  </Form.Field>
                  <Form.Field>
                    <ReactDatePicker
                      selected={
                        pageRequest.where?.createdAt.split(";")[1]
                          ? moment(
                              pageRequest.where?.createdAt.split(";")[1],
                              "YYYY-MM-DD",
                            ).toDate()
                          : undefined
                      }
                      onChange={(d) =>
                        setFilter(
                          "createdAt",
                          `${pageRequest.where?.createdAt.split(";")[0]};` +
                            (d
                              ? moment(d).format("YYYY-MM-DD")
                              : moment(new Date("2030-12-12")).format(
                                  "YYYY-MM-DD",
                                )),
                        )
                      }
                      dateFormat="dd MMM yyyy"
                      placeholderText="Select Date To"
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {syncLog && (
          <PatientLogModal
            syncLog={syncLog}
            onClose={() => setSyncLog(undefined)}
          />
        )}
        <Table striped={true} compact={true} selectable={true}>
          <Table.Header>
            <Table.HeaderCell>
              {getSorting("createdAt", "Starting")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {getSorting("updatedAt", "Completed")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {getSorting("practice.practice", "Practice")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {getSorting("status", "Status")}
            </Table.HeaderCell>
            <Table.HeaderCell>Logs</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {pagedData.data.map((data) => (
              <Table.Row
                onClick={() => setSyncLog(data)}
                className="cursor-pointer"
                key={data.id + "_log"}
              >
                <Table.Cell
                  content={moment(data.createdAt).format("DD MMMM YYYY @HH:mm")}
                />
                <Table.Cell
                  content={moment(data.updatedAt).format("DD MMMM YYYY @HH:mm")}
                />
                <Table.Cell content={data?.practice?.practice} />
                <Table.Cell content={data?.status} />
                <Table.Cell content={logsTags(data?.patientSyncLogs ?? [])} />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pagination
          onPageChange={(_, data) => onPageChange(data)}
          activePage={Math.ceil(offset / limit) + 1}
          totalPages={Math.ceil(pagedData.count / limit)}
          boundaryRange={5}
        />
      </div>
    </Template>
  );
};

export default PatientLogs;
