export const getSummary =
  (type = "week") =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      return await api.get(`dashboard/summary?type=${type}`);
    } catch (err) {
      console.error(err);
      return null;
    }
  };
