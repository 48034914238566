import { AuthDetails } from "../models/AuthDetails";

export const setAuth = (auth: AuthDetails) => {
  localStorage.setItem("persist:root", JSON.stringify(auth));
};

export const removeAuth = () => {
  localStorage.removeItem("persist:root");
};

export const useAuth = (): AuthDetails => {
  return JSON.parse(localStorage.getItem("persist:root") ?? "{}");
};

export const saveCurrentPage = () => {
  localStorage.setItem("redirectPath", window.location.pathname);
};

export const getCurrentPage = () => {
  return localStorage.getItem("redirectPath");
};

export const removeCurrentPage = () => {
  return localStorage.removeItem("redirectPath");
};
