import { convertToQueryParams } from "../../utils/converter";
export const getMcoPatPlanConfigs =
  ({ limit, offset, where }: any) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      return await api.get(
        `mco-patplan-configs?limit=${limit}&offset=${offset}&${convertToQueryParams(
          where,
        )}`,
      );
    } catch (err) {
      console.error(err);
      return null;
    }
  };
export const saveMcoPatPlanConfig =
  (data: any) => async (dispatch: Function, _getState: Function, api: any) => {
    try {
      return await api.post(`mco-patplan-configs`, data);
    } catch (err) {
      console.error(err);
      return null;
    }
  };
export const deleteConfiguration =
  (practiceId: number, mco: string) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      return await api.delete(`mco-patplan-configs/${practiceId}/${mco}`);
    } catch (error) {
      console.error(error);
      return 0;
    }
  };
export const updateConfiguration =
  (data: any) => async (dispatch: Function, _getState: Function, api: any) => {
    try {
      const result = api.patch(`mco-patplan-configs`, data);
      return result;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };
