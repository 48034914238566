import React, { useState } from "react";
import { Button, Card, Grid, Label, Modal } from "semantic-ui-react";
import {
  PatientStatus,
  STATUS_COLOR_MAP,
  STATUS_MESSAGE_MAP,
} from "../models/PatientStatus";
import { Patient } from "../models/Patient";
import PatientSyncLogModal from "./PatientSyncLogModal";
import * as PatientLogActions from "../redux/actions/PatientLogActions";
import { useActions } from "../redux/actions/UseActions";
import { mapPatientInformation } from "../utils/converter";

interface Prop {
  patient: Patient;
  onClose: any;
  onRetry?: any;
}

const PatientModal = ({ patient, onClose, onRetry }: Prop) => {
  const patientLogActions = useActions(PatientLogActions);
  const initalState = {
    loading: false,
    view: false,
    patient: {},
    data: {} as any,
  };
  const [state, setState] = useState(initalState);

  const getPatientSyncLog = async (patient: any) => {
    setState({ ...state, loading: true });
    const data = await patientLogActions.getPatientSyncLogs({
      patientId: patient.id,
      practiceId: patient.practiceId,
    });
    setState({
      ...state,
      view: true,
      loading: false,
      patient: patient,
      data: data.patientSyncLogs,
    });
  };

  const patientInfo = mapPatientInformation(patient);

  return (
    <Modal onClose={onClose} open={true} size="large">
      <Modal.Header>
        Patient Details
        <Label
          content={patient.status}
          className="float-end float-right"
          color={STATUS_COLOR_MAP[patient.status] ?? "grey"}
        />
      </Modal.Header>
      <Modal.Content>
        <h1 className="text-center">
          {patient.firstName} {patient.lastName}
        </h1>
        <Grid>
          <Grid.Row>
            {Object.entries(patientInfo).map(([key, value]: any) => (
              <Grid.Column width={8}>
                <Card style={{ boxShadow: "none" }}>
                  <Card.Content>
                    <b>{key}:</b> {value}
                  </Card.Content>
                </Card>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
        <br />
        <p className="text-center">
          <b>***{STATUS_MESSAGE_MAP[patient.status]}</b>
        </p>
        <br />
        {patient.status == PatientStatus.FAILED && (
          <Button
            basic
            primary
            content="Retry"
            className="float-right"
            onClick={() => onRetry(patient.id)}
          />
        )}
        <Button
          primary
          content="View sync log"
          className="float-right"
          loading={state.loading}
          onClick={() => getPatientSyncLog(patient)}
        />
        <Button
          basic
          secondary
          content="Close"
          className="float-right"
          onClick={() => onClose()}
        />
        <br />
        <br />
        {state.view && (
          <PatientSyncLogModal
            logs={state.data}
            patient={state.patient}
            onClose={() => setState(initalState)}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default PatientModal;
