import { HELP_PAGE } from "../../utils/HELP_PAGE";
import { Icon, Modal } from "semantic-ui-react";
import { useActions } from "../../redux/actions/UseActions";
import * as HelpActions from "../../redux/actions/HelpActions";
import React, { useEffect, useState } from "react";
import { ViewFileModal } from "../ViewFileModal";
import Loader from "../Loader";

export const HelpPageModal = ({
  helpPage,
  hideHelpPageModal,
}: {
  helpPage: HELP_PAGE;
  hideHelpPageModal: any;
}) => {
  const helpAction = useActions(HelpActions);
  const [help, setHelp] = useState({
    data: [],
  });
  const [fileModal, setFileModal] = useState({
    openFileModal: "",
    files: "",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadHelp().then();
  }, []);

  const openFileModal = (type: string, files: any) => {
    setFileModal({ openFileModal: type, files: files });
  };

  const closeFilesModal = () => {
    setFileModal({ openFileModal: "", files: "" });
  };

  const loadHelp = async () => {
    setLoading(true);
    const data = await helpAction.getHelp(helpPage);
    data && setHelp({ data: data });
    setLoading(false);
  };

  return (
    <Modal open={true} onClose={hideHelpPageModal} size="large" closeIcon>
      <Modal.Header>
        <h3 className="text-center">HELP</h3>
        {loading && <Loader></Loader>}
      </Modal.Header>
      <Modal.Content>
        <ul className="helpList mb-0">
          {help.data ? (
            help.data.map((item: any) => {
              return (
                <li key={item.id}>
                  <strong>{item.heading}</strong>
                  <div>
                    {item.description && (
                      <p className="mb-2">{item.description}</p>
                    )}
                    {item.fileLinks &&
                      item.fileLinks.toLowerCase().indexOf(".mp4") > 0 && (
                        <span
                          onClick={() => openFileModal("Video", item.fileLinks)}
                          className="cursorPointer"
                        >
                          <Icon
                            className="primaryColor mr-2"
                            name="play circle"
                          />
                          <span className="linkText">Watch Video</span>
                        </span>
                      )}
                    {item.fileLinks &&
                      item.fileLinks.toLowerCase().indexOf(".pdf") > 0 && (
                        <span
                          onClick={() => openFileModal("PDF", item.fileLinks)}
                          className="cursorPointer"
                        >
                          <Icon className="primaryColor mr-2" name="file pdf" />
                          <span className="linkText">View PDF</span>
                        </span>
                      )}
                  </div>
                </li>
              );
            })
          ) : (
            <div>No record found.</div>
          )}
        </ul>
      </Modal.Content>
      {fileModal.files && (
        <ViewFileModal
          closeModal={closeFilesModal}
          files={fileModal.files}
          type={fileModal.openFileModal}
        />
      )}
    </Modal>
  );
};
