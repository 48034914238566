import { combineReducers } from "redux";
import { authReducer } from "./AuthReducer";

const rootReducer = () => {
  return combineReducers({
    auth: authReducer,
  });
};

export default rootReducer;
