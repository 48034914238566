import { Label, Modal, Table } from "semantic-ui-react";
import { STATUS_COLOR_MAP, STATUS_MESSAGE_MAP } from "../models/PatientStatus";
import moment from "moment";

interface Props {
  onClose: () => any;
  patient: any;
  logs: any;
}

const PatientSyncLogModal = ({ onClose, patient, logs }: Props) => {
  return (
    <Modal onClose={onClose} open={true} size="large">
      <Modal.Header>
        {patient.firstName} {patient.lastName}
        <Label
          className="float-end float-right"
          as="a"
          size="large"
          basic
          color="blue"
        >
          <strong> {patient.practice.practice}</strong>
        </Label>
      </Modal.Header>
      <Modal.Content>
        <Table compact={true} striped={true} selectable={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content="Subscriber ID" />
              <Table.HeaderCell content="Pat Num" />
              <Table.HeaderCell content="Pat Status" />
              <Table.HeaderCell content="Log Status" />
              <Table.HeaderCell content="Result" />
              <Table.HeaderCell content="Date" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(logs ?? []).map((log: any) => (
              <Table.Row>
                <Table.Cell content={log.patient?.subscriberId} />
                <Table.Cell content={log.patient?.patNum} />
                <Table.Cell>
                  <Label
                    content={log.patient?.status}
                    color={STATUS_COLOR_MAP[log.patient?.status] ?? "grey"}
                  />
                </Table.Cell>
                <Table.Cell
                  content={
                    <Label
                      content={log.completed ? "COMPLETED" : "NOT COMPLETED"}
                      color={log.completed ? "green" : "orange"}
                    />
                  }
                />
                <Table.Cell
                  content={
                    STATUS_MESSAGE_MAP[log.patient?.status] ?? log.message
                  }
                />
                <Table.Cell
                  content={moment(log.createdAt).format("DD MMMM YYYY @HH:mm")}
                />
              </Table.Row>
            ))}
            {!logs && (
              <Table.Row style={{ textAlign: "center" }}>
                <Table.Cell colSpan="6">
                  <h4>No Data</h4>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
};

export default PatientSyncLogModal;
