import { ActionType } from "../../models/ActionType";
import { ProfileRequest } from "../../models/ProfileRequest";
import { LoginRequest } from "../../models/LoginRequest";
import { AuthDetails } from "../../models/AuthDetails";

export const LoginWithGoogle =
  (request: ProfileRequest) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      const resp = await api.post("auth/login/external?jwt=1", request);
      dispatch({ type: ActionType.LOGIN, payload: resp });
      return resp;
    } catch (err) {
      dispatch({ type: ActionType.LOGIN_FAILED, payload: err });
      return null;
    }
  };

export const Login =
  (request: LoginRequest) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      const resp: AuthDetails = await api.post("auth/login?jwt=1", request);
      dispatch({ type: ActionType.LOGIN, payload: resp });
      return resp;
    } catch (err) {
      dispatch({ type: ActionType.LOGIN_FAILED, payload: err });
      return null;
    }
  };

export const cdpLogin =
  (token: string) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      const resp: AuthDetails = await api.get(`auth/login/cdp/${token}`);
      dispatch({ type: ActionType.LOGIN, payload: resp });
      return resp;
    } catch (err) {
      dispatch({ type: ActionType.LOGIN_FAILED, payload: err });
      return null;
    }
  };

export const getAllPermission =
  () => async (dispatch: Function, _getState: Function, api: any) => {
    try {
      const resp = await api.get("permission/all");
      dispatch({ type: ActionType.LOGIN, payload: resp });
      return resp;
    } catch (err) {
      dispatch({ type: ActionType.LOGIN_FAILED, payload: err });
      return null;
    }
  };

export const getGoogleUserDetails =
  (token: string) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      const resp: AuthDetails = await api.getGoogle(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
        "",
        "",
        token,
      );
      dispatch({ type: ActionType.LOGIN, payload: resp });
      return resp;
    } catch (err) {
      dispatch({ type: ActionType.LOGIN_FAILED, payload: err });
      return null;
    }
  };
