import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import AppRedux from "./AppRedux";
import "semantic-ui-css/semantic.css";
import "./styles/css/styles.css";

const rootElement = document.getElementById("root") as HTMLElement;
ReactDOM.render(<AppRedux />, rootElement);
reportWebVitals();
