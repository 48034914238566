import { ApiService } from "./services/ApiService";
import { IRequestParams } from "../models/IRequestParams";

export class Api extends ApiService {
  baseApiUrl: string;

  constructor(url: string) {
    super();
    this.baseApiUrl = url;
  }

  public async get(url: string, data: any, query: any, accessToken?: string) {
    const requestParams: IRequestParams = {
      method: "GET",
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query,
      accessToken: accessToken,
    };
    return this.executeRequest(requestParams);
  }

  public async getGoogle(
    url: string,
    data: any,
    query: any,
    accessToken?: string,
  ) {
    const requestParams: IRequestParams = {
      method: "GET",
      url: `${url}`,
      body: data,
      queryParameters: query,
      accessToken: accessToken,
    };
    return this.executeRequestGoogle(requestParams);
  }

  public async post(url: string, data: any, query: any) {
    const requestParams: IRequestParams = {
      method: "POST",
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query,
    };
    return this.executeRequest(requestParams);
  }

  public async put(url: string, data: any, query: any) {
    const requestParams: IRequestParams = {
      method: "PUT",
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query,
    };
    return this.executeRequest(requestParams);
  }

  public async patch(url: string, data: any, query: any) {
    const requestParams: IRequestParams = {
      method: "PATCH",
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query,
    };
    return this.executeRequest(requestParams);
  }

  public async delete(
    url: string,
    data: any,
    query: any,
    accessToken?: string,
  ) {
    const requestParams: IRequestParams = {
      method: "DELETE",
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query,
      accessToken: accessToken,
    };
    return this.executeRequest(requestParams);
  }
}
