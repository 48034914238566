import React, { Component } from "react";
import { ErrorBoundaryProps } from "../models/ErrorBoundaryProps";
import { ErrorBoundaryState } from "../models/ErrorBoundaryState";

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-container">
          <p>Oh-no! Something went wrong</p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
