import { TemplateProp } from "../models/TemplateProp";
import { Segment } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import MainHeader from "./MainHeader";
import MainSideBar from "./MainSideBar";
import Loader from "./Loader";
import { CDP_MY_URL } from "../utils/constants";
import { useAuth } from "../providers/AuthProvider";

const Template: React.FC<TemplateProp> = (props: TemplateProp) => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const auth = useAuth();
  useEffect(() => {}, [auth?.accessToken]);

  const listenerFromParent = (visibility?: boolean): void => {
    const value = visibility ?? !isSideMenuOpen;
    setIsSideMenuOpen(value);
  };

  const redirectToCDPMySite = async (e: any) => {
    e.preventDefault();
    window.location.href = `${CDP_MY_URL}/?token=${auth.accessToken}`;
  };

  return (
    <div>
      <MainHeader
        toggleSideTab={listenerFromParent}
        logoRedirect={redirectToCDPMySite}
      />
      <MainSideBar
        visible={isSideMenuOpen}
        toggleSideTab={listenerFromParent}
        logoRedirect={redirectToCDPMySite}
      />
      <Segment basic>
        <div
          className="parentContainer"
          onClick={() => listenerFromParent(false)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              listenerFromParent(false);
            }
          }}
        >
          {props.loading && <Loader />}
          {props.children}
        </div>
      </Segment>
    </div>
  );
};

export default Template;
