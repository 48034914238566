import { IRequestParams } from "../../models/IRequestParams";
import request from "superagent";
import { removeAuth, saveCurrentPage } from "../../providers/AuthProvider";

export abstract class ApiService {
  protected async executeRequest(params: IRequestParams) {
    const authData = JSON.parse(localStorage.getItem("persist:root") ?? "{}");
    const authorization = authData?.accessToken
      ? `Bearer ${authData.accessToken}`
      : "";

    try {
      const req = this.createRequest(params, authorization);
      const response = await this.sendRequest(req);
      return response.body;
    } catch (error: any) {
      this.handleError(error);
      if (error.status === 401) {
        removeAuth();
        saveCurrentPage();
        window.location.href = "/";
      }
    }
  }

  protected async executeRequestGoogle(params: IRequestParams) {
    const authorization = params.accessToken
      ? `Bearer ${params.accessToken}`
      : "";
    try {
      const req = this.createRequestGoogle(params, authorization);
      const response = await this.sendRequest(req);
      return response.body;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  private createRequest(
    params: IRequestParams,
    authorization: string,
  ): request.Request {
    const req = request(params.method, params.url).set(
      "Content-Type",
      "application/json",
    );
    if (params.queryParameters) {
      req.query(params.queryParameters);
    }

    if (authorization) {
      req.set("Authorization", authorization);
    }

    if (params.body) {
      req.send(params.body);
    }

    return req;
  }

  private createRequestGoogle(
    params: IRequestParams,
    authorization: string,
  ): request.Request {
    const req = request(params.method, params.url).set(
      "Accept",
      "application/json",
    );

    if (authorization) {
      req.set("Authorization", authorization);
    }

    return req;
  }

  private async sendRequest(req: request.Request): Promise<Response> {
    return new Promise((resolve, reject) => {
      req.end((error: any, response: any) => {
        if (error || !response.ok) {
          reject(error || response.body);
        } else {
          resolve(response);
        }
      });
    });
  }

  private handleError(error: any): any {
    if (error?.code) {
      return { ...error, code: Number(error.code) };
    }
    return error;
  }
}
