import React from "react";
import { Provider } from "react-redux";
import configureStore from "./redux/Configure";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import Loader from "./components/Loader";
import { clientId } from "./utils/config";
import { GoogleOAuthProvider } from "@react-oauth/google";
const { _persistStore, store } = configureStore();
const AppRedux: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={_persistStore}>
        <GoogleOAuthProvider clientId={clientId}>
          <App />
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  );
};

export default AppRedux;
