import Template from "../components/Template";
import React, { useEffect, useState } from "react";
import {Button, Dropdown, Icon, Modal, Popup, TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table
} from "semantic-ui-react";
import { useActions } from "../redux/actions/UseActions";
import * as McoPatPlanConfigActions from "../redux/actions/McoPatPlanConfigActions";
import * as PracticeActions from "../redux/actions/PracticeActions";
import { PageRequest, PageResponse } from "../models/Pagination";
import moment from "moment";
import { Practice } from "../models/Practice";
import Swal from "sweetalert2";
import { HelpMark } from "../components/help/HelpMark";
import { HELP_PAGE } from "../utils/HELP_PAGE";
import McoPatPlanConfigurationModal from "../components/McoPatPlanConfigurations/McoPatPlanConfigurationModal";
import { McoPatPlanConfig } from "../models/McoPatPlanConfig";
const McoPatPlanConfigurations = () => {
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const mcoPatPlanConfigActions = useActions(McoPatPlanConfigActions);
  const practiceActions = useActions(PracticeActions);
  const [pagedApiConfigs, setPagedMcoPatPlanConfigs] = useState<
    PageResponse<McoPatPlanConfig>
  >({
    data: [],
    count: 0,
  });
  const [practices, setPractices] = useState<PageResponse<Practice>>({
    data: [],
    count: 0,
  });
  const [pageRequest, setPageRequest] = useState<PageRequest>({
    limit: itemsPerPage,
    offset: 0,
    where: {},
  });
  const { limit, offset } = pageRequest;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentConfig, setCurrentConfig] = useState({} as McoPatPlanConfig);
  const [state, setState] = useState({
    openModal: false,
  });

  const [expandedRowIndex, setExpandedRowIndex] = useState(-1);
  const closeModal = async () => {
    setState({ openModal: false });
    setCurrentConfig({} as McoPatPlanConfig);
    setEditMode(false);
    await loadMcoPatPlanConfigs();
  };
  useEffect(() => {
    loadMcoPatPlanConfigs();
    loadPractices();
  }, []);
  useEffect(() => {
    loadMcoPatPlanConfigs();
  }, [pageRequest]);
  const loadMcoPatPlanConfigs = async () => {
    setLoading(true);
    const data: PageResponse<McoPatPlanConfig> =
      await mcoPatPlanConfigActions.getMcoPatPlanConfigs({
        limit,
        offset,
        where: pageRequest.where,
      });
    data && setPagedMcoPatPlanConfigs(data);
    setLoading(false);
  };
  const loadPractices = async () => {
    setLoading(true);
    const data = await practiceActions.getPractices();
    data && setPractices(data);
    setLoading(false);
  };
  const deleteConfiguration = async (
    practiceId: number | undefined,
    mco: string,
  ) => {
    setLoading(true);
    const response = await mcoPatPlanConfigActions.deleteConfiguration(
      practiceId,
      mco,
    );
    if (response?.rowCount) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "The Api configuration has been deleted successfully.",
        showConfirmButton: false,
        timer: 4000,
        toast: true,
        background: "#105063",
        color: "white",
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title:
          "The deletion of a this Api configuration failed. Please verify whether a configuration for the that practice already exists.",
        showConfirmButton: false,
        timer: 6000,
        toast: true,
        background: "#932121",
        color: "white",
      });
    }
    setShowDeleteModal(false);
    setCurrentConfig({} as McoPatPlanConfig);
    await loadMcoPatPlanConfigs();
    setLoading(false);
  };
  const revealDeleteModal = async (mcoPatPlanConfig: McoPatPlanConfig) => {
    setCurrentConfig(mcoPatPlanConfig);
    setShowDeleteModal(true);
  };
  const editConfig = async (mcoPatPlanConfig: McoPatPlanConfig) => {
    setCurrentConfig(mcoPatPlanConfig);
    setEditMode(true);
    setState({ openModal: true });
  };
  const [loading, setLoading] = useState(true);
  const [selectedPractice, setSelectedPractice] = useState("");
  const handleSelectInputChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: any,
  ) => {
    setSelectedPractice(data.value);
    setPageRequest({ ...pageRequest, where: { practiceId: data.value } });
  };
  const groupByPracticeId = (data: any[]) => {
    return data.reduce((acc, item) => {
      let practiceGroup = acc.find(
        (p: { practiceId: any }) => p.practiceId === item.practiceId,
      );
      if (!practiceGroup) {
        practiceGroup = {
          practiceId: item.practiceId,
          practice: item.practice,
          mcos: [],
        };
        acc.push(practiceGroup);
      }
      practiceGroup.mcos.push({
        practiceId: item.practiceId,
        practice: item.practice,
        mco: item.mco,
        patPlan: item.patPlan,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
      });
      return acc;
    }, []);
  };
  let practiceOptions = practices?.data.map((practice, key) => ({
    text: practice.practice,
    value: practice.id,
    key,
  }));
  let availablePractices: Practice[] = [];
  if (practiceOptions) {
    if (practiceOptions[0]?.text !== "All Practices") {
      practiceOptions?.unshift({
        text: "All Practices",
        value: 0,
        key: 0,
      });
    }
    availablePractices = practices.data;
  }
  const handleRowClick = (index: number) => {
    setExpandedRowIndex( expandedRowIndex === index ? -1 : index);
  };

  return (
    <Template activeLink="dashboard" loading={loading}>
      <div>
        <div className="ui card" style={{ padding: "10px", width: "100%" }}>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div>
              <h1>
                MCO PatPlan Configurations
                <HelpMark helpPage={HELP_PAGE.Mco_PatPlan_Configurations} />
              </h1>
            </div>
            <div
              style={{
                float: "right",
                display: "flex",
                columnGap: "10px",
                width: "25%",
              }}
            >
              <Dropdown
                search={true}
                disabled={editMode}
                required
                placeholder="Select Practice"
                fluid
                selection
                options={practiceOptions}
                value={selectedPractice}
                onChange={(e, data) => handleSelectInputChange(e, data)}
              />
              <Button
                content="Add PatPlan"
                secondary
                labelPosition="right"
                icon="plus"
                onClick={() => setState({ openModal: true })}
                primary
              />
            </div>
          </div>
        </div>

        <Table color={"orange"} compact striped>
          <TableHeader>
            <TableRow>
              <TableHeaderCell width={1}/>
              <TableHeaderCell>Practice</TableHeaderCell>
              <TableHeaderCell>Action</TableHeaderCell>
              <TableHeaderCell>MCO</TableHeaderCell>
              <TableHeaderCell>PatPlan</TableHeaderCell>
              <TableHeaderCell>Date Created</TableHeaderCell>
              <TableHeaderCell>Date Updated</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {
              groupByPracticeId(pagedApiConfigs.data).map((row: any, index: number) => {
                return <React.Fragment>
                  <TableRow>
                    <TableCell width={1}>
                      <Icon
                          name={expandedRowIndex === index ? 'angle down' : 'angle right'}
                          onClick={() => handleRowClick(index)}
                          style={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                    <TableCell>
                      {row.practice.practice}
                    </TableCell>
                    <TableCell colSpan="5"/>
                  </TableRow>
                  {expandedRowIndex === index && (
                      row.mcos.map((mco: any, key: number) => {
                        return <TableRow key={key}>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Button.Group compact size="mini">
                              <Popup
                                  content={`Delete ${mco.practice?.practice}'s configuration.`}
                                  trigger={
                                    <Button
                                        color={"red"}
                                        icon
                                        onClick={() => revealDeleteModal(mco)}
                                    >
                                      <Icon name="trash alternate outline" className="mr10"/>
                                    </Button>
                                  }
                                  inverted
                              />
                              <Popup
                                  inverted
                                  content={`Edit ${mco.practice?.practice}'s config.`}
                                  trigger={
                                    <Button primary icon onClick={() => editConfig(mco)}>
                                      <Icon name="edit outline" className="mr10"/>
                                    </Button>
                                  }
                              />
                            </Button.Group>
                          </TableCell>
                          <TableCell>{mco.mco}</TableCell>
                          <TableCell>{mco.patPlan}</TableCell>
                          <TableCell>{moment(mco.createdAt).format('DD MMMM YYYY @HH:mm')}</TableCell>
                          <TableCell>{moment(mco.updatedAt).format('DD MMMM YYYY @HH:mm')}</TableCell>
                        </TableRow>
                      }))
                  }
                </React.Fragment>

              })
            }
          </TableBody>
        </Table>
        <div style={{ margin: "60px" }}></div>
      </div>
      <McoPatPlanConfigurationModal
        open={state.openModal}
        onClose={closeModal}
        practices={availablePractices}
        editMode={editMode}
        data={currentConfig}
      />
      <Modal
        size="small"
        open={showDeleteModal}
        closeIcon
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Header>Delete Api Configuration.</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to delete the api configuration for the{" "}
            <b>{currentConfig.practice?.practice}</b> practice and <b>MCO</b>:{" "}
            {currentConfig.mco}?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary negative onClick={() => setShowDeleteModal(false)}>
            No, Cancel
          </Button>
          <Button
            primary
            positive
            onClick={() =>
              deleteConfiguration(currentConfig?.practiceId, currentConfig?.mco)
            }
          >
            Yes, Proceed
          </Button>
        </Modal.Actions>
      </Modal>
    </Template>
  );
};
export default McoPatPlanConfigurations;
