import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { Button, Form, Grid } from "semantic-ui-react";
import { useActions } from "../redux/actions/UseActions";
import * as AuthActions from "../redux/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import {
  getCurrentPage,
  removeCurrentPage,
  useAuth,
} from "../providers/AuthProvider";
import { defaultHomePage } from "../utils/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthDetails } from "../models/AuthDetails";

const SignIn = () => {
  const navigate = useNavigate();
  const swal = withReactContent(Swal);
  const auth: AuthDetails = useAuth();

  const redirectToMainPage = useCallback(() => {
    const redirectPath = getCurrentPage();
    if (redirectPath) {
      removeCurrentPage();
      navigate(`${redirectPath}`);
      return;
    }
    navigate(`/${defaultHomePage}`);
  }, [navigate]);

  useEffect(() => {
    if (auth?.accessToken) {
      redirectToMainPage();
    }
  }, [auth?.accessToken, redirectToMainPage]);

  const initialState = {
    username: "",
    password: "",
  };
  const [state, setState] = useState(initialState);
  const authActions = useActions(AuthActions);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const login = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { username, password } = state;
    const obj = { username: username, password: password };
    const result = await authActions.Login(obj);
    if (result) {
      redirectToMainPage();
    } else {
      await swal.fire({
        title: "Error",
        text: "Failed to login",
        icon: "error",
      });
    }
  };

  return (
    <div className="login">
      <div className="container-fluid">
        <Grid>
          <Grid.Row>
            <Grid.Column className="sectionLeft" computer={8} tablet={6}>
              {" "}
            </Grid.Column>
            <Grid.Column computer={8} tablet={10} mobile={16}>
              <img
                src="/images/logoMain.png"
                className="loginLogo"
                alt="Logo"
              ></img>
              <Grid centered>
                <Grid.Row centered>
                  <Grid.Column computer={14} tablet={16} mobile={16}>
                    <Grid>
                      <Grid.Row centered>
                        <Grid.Column computer={8} tablet={8} mobile={14}>
                          <Form
                            className="mt10"
                            onSubmit={(event: FormEvent<HTMLFormElement>) =>
                              login(event)
                            }
                          >
                            <Form.Field>
                              <label>USERNAME</label>
                              <input
                                className="form-control"
                                type="text"
                                name="username"
                                value={state.username || ""}
                                onChange={onChange}
                                required
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>PASSWORD</label>
                              <input
                                className="form-control"
                                type="password"
                                name="password"
                                value={state.password || ""}
                                onChange={onChange}
                                required
                              />
                            </Form.Field>
                            <Form.Field>
                              <Button
                                primary
                                className="width-100"
                                size="large"
                              >
                                Login
                              </Button>
                            </Form.Field>
                          </Form>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default SignIn;
