import React, { useEffect, useState } from "react";
import {
  Label,
  Modal,
  Pagination,
  PaginationProps,
  Table,
} from "semantic-ui-react";
import { PatientLog, PatientSyncLog } from "../models/PatientLog";
import moment from "moment/moment";
import { STATUS_COLOR_MAP, STATUS_MESSAGE_MAP } from "../models/PatientStatus";
import { PageRequest, PageResponse } from "../models/Pagination";

const PatientLogModal = ({
  syncLog,
  onClose,
}: {
  syncLog: PatientLog;
  onClose: any;
}) => {
  const [pagedData, setPagedData] = useState<PageResponse<PatientSyncLog>>({
    data: [],
    count: 0,
  });
  const [pageRequest, setPageRequest] = useState<PageRequest>({
    limit: 15,
    offset: 0,
  });
  const { limit, offset } = pageRequest;

  const onPageChange = (data: PaginationProps) => {
    setPageRequest({ offset: (+(data.activePage ?? 1) - 1) * limit, limit });
  };

  useEffect(() => {
    if (syncLog) {
      const data = syncLog.patientSyncLogs ?? [];
      setPagedData({
        count: data.length,
        data: data.slice(offset, offset + limit),
      });
    }
  }, [syncLog, pageRequest]);

  return (
    <Modal onClose={onClose} open={true} size="large">
      <Modal.Content>
        <h1 className="text-center">
          {syncLog.practice.practice} ({syncLog.patientSyncLogs?.length ?? 0})
        </h1>
        <Table compact={true} striped={true} selectable={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content="Subscriber ID" />
              <Table.HeaderCell content="Pat Num" />
              <Table.HeaderCell content="Pat Status" />
              <Table.HeaderCell content="Log Status" />
              <Table.HeaderCell content="Result" />
              <Table.HeaderCell content="Date" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(pagedData.data ?? []).map((log) => (
              <Table.Row>
                <Table.Cell content={log.patient?.subscriberId} />
                <Table.Cell content={log.patient?.patNum} />
                <Table.Cell>
                  <Label
                    content={log.patient?.status}
                    color={STATUS_COLOR_MAP[log.patient?.status] ?? "grey"}
                  />
                </Table.Cell>
                <Table.Cell
                  content={
                    <Label
                      content={log.completed ? "COMPLETED" : "NOT COMPLETED"}
                      color={log.completed ? "green" : "orange"}
                    />
                  }
                />
                <Table.Cell
                  content={
                    STATUS_MESSAGE_MAP[log.patient?.status] ?? log.message
                  }
                />
                <Table.Cell
                  content={moment(log.createdAt).format("DD MMMM YYYY @HH:mm")}
                />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pagination
          onPageChange={(_, data) => onPageChange(data)}
          activePage={Math.ceil(offset / limit) + 1}
          totalPages={Math.ceil(pagedData.count / limit)}
          siblingRange={5}
        />
      </Modal.Content>
    </Modal>
  );
};

export default PatientLogModal;
