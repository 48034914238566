import React from "react";
import { Dimmer, Icon } from "semantic-ui-react";

const Loader = () => {
  return (
    <Dimmer active>
      <Icon name="circle notch" loading={true} size="huge" />
    </Dimmer>
  );
};

export default Loader;
