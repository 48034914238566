import * as React from "react";
import { HELP_PAGE } from "../../utils/HELP_PAGE";
import { HelpPageModal } from "./HelpPageModal";

interface HelpMarkProps {
  helpPage: HELP_PAGE;
}

export const HelpMark: React.FC<HelpMarkProps> = ({ helpPage }) => {
  const [helpPageModal, setHelpPageModal] = React.useState<boolean>(false);

  const openHelpPage = (pageId: number) => {
    setHelpPageModal(true);
  };

  const hideHelpPageModal = () => {
    setHelpPageModal(false);
  };

  return (
    <div className="helpMark">
      <img
        onClick={() => openHelpPage(helpPage)}
        alt="help"
        className="cursorPointer"
        src={"/images/help-icon.png"}
      />
      {helpPageModal && (
        <HelpPageModal
          hideHelpPageModal={hideHelpPageModal}
          helpPage={helpPage}
        />
      )}
    </div>
  );
};
