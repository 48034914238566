import { PageRequest } from "../../models/Pagination";

export const getLogs =
  ({ limit, offset, where, orderBy, direction }: PageRequest) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      return await api.get(
        `patient-logs?limit=${limit}&offset=${offset}&where=${createQueryString(
          where,
        )}&direction=${direction}&orderBy=${orderBy}`,
      );
    } catch (err) {
      console.error(err);
      return null;
    }
  };

export const getPatientSyncLogs =
  ({ patientId, practiceId }: { patientId: number; practiceId: string }) =>
  async (dispatch: Function, _getState: Function, api: any) => {
    try {
      return await api.get(
        `patient-logs/trail?patientId=${patientId}&practiceId=${practiceId}`,
      );
    } catch (err) {
      console.error(err);
      return [];
    }
  };

const createQueryString = (obj: any) => {
  return Object.entries(obj ?? {})
    .filter(([key, value]) => key && value && `${value as string}`.length)
    .map(([key, value]) => `${key}=${value}`)
    .join(",");
};
