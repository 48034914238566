export function convertToCSV(header: any, data: any) {
  const rows = data.map((item: any) =>
    Object.values(item).map(escapeCSVValue).join(","),
  );
  return [header, ...rows].join("\n");
}

function escapeCSVValue(value: any) {
  // Check if the value contains special characters like quotes or commas
  if (/[",\n]/.test(value)) {
    // If it does, wrap the value in double quotes and escape any existing double quotes
    return `"${value.replace(/"/g, '""')}"`;
  }
  return value;
}

export const exportedStatus = [
  { text: "All", value: -1, key: 1 },
  { text: "Exported", value: 1, key: 2 },
  { text: "Not Exported", value: 0, key: 3 },
];
